<template>
  <b-container class="report-account-plan-2 report-account-plan-asset-allocation-form-table bg-light page-wrapper">
    <div class="main-content-wrapper">
      <b-row class="mb-0 pb-0" no-gutters>
        <b-breadcrumb :items="breadcrumbItems" class="dark"></b-breadcrumb>
      </b-row>

      <b-row class="mb-0 pb-0" no-gutters>
        <h1 class="text-left">{{ $t(translationPath + 'title_assets') }}</h1>
      </b-row>
      <account-plan-submenu active="assets"></account-plan-submenu>

      <div class="wrapper_white_with_border py-4">

        <account-plan-assets-header></account-plan-assets-header>

        <b-card no-body class="white-tabs-card">
          <b-tabs card class="white-tabs" @activate-tab="onTabActivate">
            <AssetsMenu>
              <template slot="account-plan-assets-allocation">
                <div class="account-plan-asset-classes">
                  <b-row no-gutters>
                    <b-col cols="10">
                      <div class="text-regular" v-html="$t(translationPath + 'asset.allocation.description')"></div>
                    </b-col>
                  </b-row>

                  <b-table class="spirecta-simple-table account-plan-table"
                          show-empty hover responsive striped
                          ref="metaTable"
                          stacked="md"
                          :items="accountRows"
                          :fields="fieldValues"
                          :tbodyTrClass="trClass"
                          :busy="busy"
                  >
                    <template v-slot:table-busy>
                      <loader/>
                    </template>

                    <template v-slot:cell(title)="row">
                      <template v-if="row.item && row.item.is_account === true">
                        <span class="px-2 account_title">&ndash;</span>
                        <b-link :to="'/reports/performance/accounts/' + row.item.id + '/view'" class="account_title">
                          {{ currentCOA.prefix_account_title_with_code ? row.item.title_with_code : row.item.title }}
                        </b-link>
                        <b-badge v-if="row.item.status === 'suspended'" variant="warning" class="ml-2 cursor-help"
                                :title="$t(translationPath+'badge_suspended_help')">
                            {{ $t( translationPath + 'badge_suspended') }}
                        </b-badge>
                      </template>
                      <template v-else-if="row.item">
                        <b-link :to="'/reports/performance/account-groups/'+row.item.id" class="account_group_title">
                          {{ currentCOA.prefix_account_title_with_code ? row.item.title_with_code : row.item.title }}
                        </b-link>
                      </template>
                      <template v-else></template>
                    </template>

                    <template v-for="(field, index) in metaFields" :slot="'cell(row_meta.' + field + ')'"
                              slot-scope="row">
                      <template v-if="row.item.is_account">
                        <percentage-input
                          v-model="row.item.row_meta[field]"
                          :key="index"
                          inputClass="text-right"
                          :max="100"
                          :min="0"
                          :value="row.value ? row.value : 0"
                          :inputId="'account_' + row.item.id + '_meta_' + field"
                          @input="onInput(row.item)"
                        ></percentage-input>
                      </template>
                      <template v-else>
                        <div v-bind:key="index" class="tr_heading">{{row.field.label}}</div>
                      </template>
                    </template>

                    <template v-slot:cell(meta_sum)="row">
                      <template v-if="row.item.is_account">
                        <percentage-input
                          v-model="row.item.meta_sum"
                          :value="row.value"
                          :inputId="'account_' + row.item.id + '_meta_sum'"
                          :inputClass="sumValidationClass(row.value) + ' text-right'"
                          :disabled="true"
                          :max="1000"
                        ></percentage-input>
                      </template>
                      <template v-else>
                        <div class="tr_heading">{{row.field.label}}</div>
                      </template>
                    </template>

                    <template v-slot:cell(expected_annual_return_for_input)="row">
                      <template v-if="row.item.is_account">
                        <percentage-input
                          :value="Number(row.value)"
                          :precision="2"
                          @input="(val) => onExpectedAnnualReturnInput(val, row.item)"
                        />
                      </template>
                      <template v-else><div class="tr_heading">{{row.field.label}}</div></template>
                    </template>
                  </b-table>

                  <b-row class="mb-5 mt-4">
                    <b-col class="pt-4 pt-md-1">
                      <b-button
                        type="submit"
                        variant="primary"
                        class="float-right ml-3 px-4 btn-save"
                        :disabled="saveDisabled"
                        @click="onSave"
                      >{{ $t('common.save') }}
                      </b-button>
                    </b-col>
                  </b-row>
                </div>
              </template>
            </AssetsMenu>
          </b-tabs>
        </b-card>

      </div><!-- END: wrapper_white_with_border -->
    </div><!-- END: main-content-wrapper -->

    <confirm-modal
      ref="ConfirmModal"
      :title="$t('common.please_confirm').toString()"
      :message="$t('reports.other.account_plan.confirm_page_leave_message').toString()"
      variant="primary"
      @confirmed="onLeavePageConfirmed"
    ></confirm-modal>
  </b-container>
</template>

<script>
import axios from 'axios'
import AccountPlanTabMixin from './AccountPlanTabMixin'
import PercentageInput from '@/components/common/PercentageInput'
import AssetsMenu from './submenu/AssetsMenu'
import AccountPlanAssetsHeader from './headers/AccountPlanAssetsHeader'

export default {
  name: 'AccountPlanAssetsAllocation',
  components: { PercentageInput, AssetsMenu, AccountPlanAssetsHeader },
  mixins: [AccountPlanTabMixin],
  data () {
    return {
      accountsFilter: ''
    }
  },
  computed: {
    metaFields () {
      return [
        'equities',
        'fixed_income',
        'cash',
        'gold',
        'alternatives',
        'other'
      ]
    },
    fieldValues () {
      return [
        { key: 'title', label: this.$t('common.title'), class: 'td-title' },
        { key: 'row_meta.equities', label: this.$t(this.translationPath + 'table.th_equities'), class: 'td-equities td-asset-allocation-input' },
        { key: 'row_meta.fixed_income', label: this.$t(this.translationPath + 'table.th_fixed_income'), class: 'td-fixed_income td-asset-allocation-input' },
        { key: 'row_meta.cash', label: this.$t(this.translationPath + 'table.th_cash'), class: 'td-cash td-asset-allocation-input' },
        { key: 'row_meta.gold', label: this.$t(this.translationPath + 'table.th_gold'), class: 'td-gold td-asset-allocation-input' },
        { key: 'row_meta.alternatives', label: this.$t(this.translationPath + 'table.th_alternatives'), class: 'td-alternatives td-asset-allocation-input' },
        { key: 'row_meta.other', label: this.$t(this.translationPath + 'table.th_other'), class: 'td-other td-asset-allocation-input' },
        { key: 'meta_sum', label: this.$t('common.sum'), class: 'td-sum td-asset-allocation-input' },
        { key: 'expected_annual_return_for_input', label: this.$t(this.translationPath + 'table.th_expected_annual_return'), class: 'td-expected-annual-return-input' }
      ]
    },
    accountRows () {
      if (this.accountsGrouped.asset === null || this.accountsGrouped.asset === 0) {
        return []
      }

      let notEmptyGroups = []
      this.accountsGrouped.asset.map((item) => {
        if (item.is_account /* && ['portfolio', 'pension', 'alternative_investment', 'cash_equivalent'].indexOf(item.subtype) !== -1 */) {
          notEmptyGroups.push(item.group_id)
        }
      })
      notEmptyGroups = notEmptyGroups.filter((value, index, self) => self.indexOf(value) === index) // Get unique values

      const rows = this.accountsGrouped.asset.filter((item) => {
        /* if (['portfolio', 'pension', 'alternative_investment', 'cash_equivalent'].indexOf(item.subtype) !== -1 || (!item.is_account && notEmptyGroups.indexOf(item.id) !== -1)) {
          return item
        }
        return null */
        return item
      })

      rows.map((item, index) => {
        if (item.is_account) {
          const rowMeta = {
            equities: 0,
            fixed_income: 0,
            cash: 0,
            gold: 0,
            alternatives: 0,
            other: 0
          }
          let sum = 0
          /**
           * @type {item}
           * @property {array} assets_meta - from api end point
           * */
          item.assets_meta.map((meta) => {
            if (this.metaFields.indexOf(meta.name) !== -1) {
              rowMeta[meta.name] = parseInt(meta.value)
              sum += rowMeta[meta.name]
            }
          })
          rows[index].row_meta = rowMeta
          rows[index].meta_sum = sum
        }

        /* If nothing is set up for account (sum is 0) - preset cash_equivalents subtypes to cash = 100 */
        if (!rows[index].meta_sum && rows[index].subtype === 'cash_equivalent') {
          rows[index].row_meta.cash = 100
          rows[index].meta_sum = 100
        }
      })

      return rows
    }
  },
  methods: {
    async loadData () {
      if (!this.currentCOA.locale) {
        return true
      }

      this.busy = true
      Promise.all([
        this.loadAccountGroupsWithData('asset'),
        // this.loadGlobalAccounts('asset'),
        this.loadAccountGroups('asset')
      ])
        .then(() => {
          this.busy = false
        })
    },
    sumValidationClass (val) {
      if (!this.dirty) {
        return ''
      }
      if (val !== 100 && val !== 0) {
        return 'is-invalid'
      }
      return 'is-valid'
    },
    isFormValid () {
      let status = true
      this.accountRows.map((item) => {
        if (item.is_account && (item.meta_sum !== 100 && item.meta_sum !== 0)) {
          status = false
        }
      })

      return status
    },
    /**
     * Checkes is form valid and if yes - saves data. Shows success or fail toast message.
     */
    onSave () {
      this.dirty = true
      if (!this.isFormValid()) {
        this.$bvToast.toast(this.$t(this.translationPath + 'alert_update_assets_classes_invalid_form').toString(), {
          title: this.$t(this.translationPath + 'alert_update_assets_classes_title').toString(),
          variant: 'danger',
          solid: true,
          'auto-hide-delay': 3000
        })
        this.$refs.metaTable.refresh()
        return false
      }

      this.saveDisabled = true

      Promise.all([
        this.saveMeta(),
        this.saveAccounts()
      ])
        .then(() => {
          this.$bvToast.toast(this.$t(this.translationPath + 'alert_update_assets_classes_description').toString(), {
            title: this.$t(this.translationPath + 'alert_update_assets_classes_title').toString(),
            variant: 'success',
            solid: true,
            'auto-hide-delay': 3000
          })
          this.hasUnsavedChanges = false
        })
        .catch((err) => {
          console.error(err)
          this.$bvToast.toast(this.$t(this.translationPath + 'alert_update_assets_classes_fail_description').toString(), {
            title: this.$t(this.translationPath + 'alert_update_assets_classes_title').toString(),
            variant: 'danger',
            solid: true,
            'auto-hide-delay': 3000
          })
        })
        .then(() => {
          this.saveDisabled = false
        })
    },
    /**
     * Saves asset allocation meta.
     */
    async saveMeta () {
      return new Promise((resolve, reject) => {
        const putData = { accounts_meta: {}, group_meta: 'asset_allocation' }
        this.accountRows.map(item => {
          if (item.is_account) {
            const metas = {}
            Object.keys(item.row_meta).map(key => {
              metas[key] = item.row_meta[key]
            })
            putData.accounts_meta[item.id] = metas
          }
        })

        axios.put(`${process.env.VUE_APP_ROOT_API}/accounts/meta-multiple`, putData)
          .then(() => {
            resolve(true)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    /**
     * Saves data to accounts table. On this page it is onlu expected_annual_return field.
     */
    async saveAccounts () {
      return new Promise((resolve, reject) => {
        const putData = { accounts: [] }
        this.accountRows.map(item => {
          putData.accounts.push({
            id: item.id,
            expected_annual_return: item.expected_annual_return
          })
        })

        axios.put(`${process.env.VUE_APP_ROOT_API}/accounts/update-multiple`, putData)
          .then(() => {
            resolve(true)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    onInput (item) {
      item.meta_sum = 0
      Object.keys(item.row_meta).map((key) => {
        item.meta_sum += item.row_meta[key]
      })
      this.$refs.metaTable.refresh()
      this.hasUnsavedChanges = true
    },
    onExpectedAnnualReturnInput (val, item) {
      item.expected_annual_return_for_input = val
      item.expected_annual_return = val / 100
    }
  },
  created () {
    this.loadData()
  },
  watch: {
    currentCOA: {
      deep: true,
      handler () {
        this.loadData()
      }
    }
  }
}
</script>

<style lang="scss">
@import './AccountPlan.scss';

.report-account-plan-asset-allocation-form-table
{
  .td-asset-allocation-input {
    max-width:75px;
  }
  .td-asset-allocation-input{
    input{padding-left:5px;padding-right:5px;}
  }
  .td-sum input{border:0;background-color:rgb(0,0,0,0);}
  .td-expected-annual-return-input {
    max-width: 90px;
  }
}
</style>
